body {
    margin: 0px;
}

.info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.legend {
    padding: 6px 8px;
    text-align: left;
    line-height: 24px;
    color: rgb(23, 1, 1);
}

.legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin: 0 8px 0 0;
    opacity: 0.7;
}

.info div {
    display: flex;
}

.info div span {
    position: relative;
    bottom: 3px;
}

.leaflet-image-icon div{
    border-radius: 50%;
}
